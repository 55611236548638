import urls from '../urls';
import http from '../axios';

function get() {
  return http.get(`${urls.allOrders}/`);
}

function getById(id) {
  return http.get(`${urls.orders}/${id}/`);
}

function getTotal(params) {
  let { offset = 0, from_date = '', to_date = '', query = '' } = params;

  return http.get(
    `${urls.allOrders}/?from_date=${from_date}&to_date=${to_date}&limit=10&offset=${offset}&exclude_order_type=subscription&query=${query}`
  );
}

function getCompleted(params) {
  let { offset = 0, from_date = '', to_date = '', query = '' } = params;
  return http.get(
    `${urls.allOrders}/?status=delivered&exclude_order_type=subscription&from_date=${from_date}&to_date=${to_date}&limit=10&offset=${offset}&query=${query}`
  );
}

function getPending(params) {
  let { offset = 0, from_date = '', to_date = '', query = '' } = params;
  return http.get(
    `${urls.allOrders}/?status=confirmed&exclude_order_type=subscription&from_date=${from_date}&to_date=${to_date}&limit=10&offset=${offset}&query=${query}`
  );
}

function getAssigned(params) {
  let { offset = 0, from_date = '', to_date = '', query = '' } = params;
  return http.get(
    `${urls.allOrders}/?status=assigned&exclude_order_type=subscription&from_date=${from_date}&to_date=${to_date}&limit=10&offset=${offset}&query=${query}`
  );
}

function getOutForDelivery(params) {
  let { offset = 0, from_date = '', to_date = '', query = '' } = params;
  return http.get(
    `${urls.allOrders}/?status=out-for-delivery&exclude_order_type=subscription&from_date=${from_date}&to_date=${to_date}&limit=10&offset=${offset}&query=${query}`
  );
}

function getOrderFiles(orderId) {
  return http.get(`api/order-files/?order_id=${orderId}`);
}

function update(data) {
  return http.patch(`${urls.orders}/${data.id}/`, data);
}

function getMyCompleted(id) {
  return http.get(`${urls.myOrders}/?assigned_user=${id}&status=delivered`);
}

function getMyCompletedDate(data) {
  return http.get(
    `${urls.myOrders}/?assigned_user=${data.id}&status=delivered&from_date=${data.from_date}&to_date=${data.to_date}`
  );
}

function getMyPending(id) {
  return http.get(
    `${urls.myOrders}/?assigned_user=${id}&exclude_status=delivered`
  );
}
function getMyOutForDelivery(id) {
  return http.get(
    `${urls.myOrders}/?assigned_user=${id}&status=out-for-delivery`
  );
}
function getMyOutForDeliveryDate(data) {
  return http.get(
    `${urls.myOrders}/?assigned_user=${data.id}&status=out-for-delivery&from_date=${data.from_date}&to_date=${data.to_date}`
  );
}
function getMyPendingDate(data) {
  return http.get(
    `${urls.myOrders}/?assigned_user=${data.id}&exclude_status=delivered&from_date=${data.from_date}&to_date=${data.to_date}`
  );
}

export default {
  get,
  getCompleted,
  update,
  getById,
  getPending,
  getTotal,
  getMyCompleted,
  getMyPending,
  getMyPendingDate,
  getMyOutForDelivery,
  getAssigned,
  getMyCompletedDate,
  getMyOutForDeliveryDate,
  getOrderFiles,
  getOutForDelivery,
};
