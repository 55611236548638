export default {
  adminLogin: '/api/admin-login',
  users: '/api/users/',
  user: '/api/profile/',
  userCoinInfo: '/api/get-user-coin-info/',
  staffUsers: '/api/staff_users/',
  orders: '/api/all-orders',
  allOrders: '/api/all-orders',
  configurations: '/api/configurations',
  myOrders: '/api/my_orders',
  orderStatus: '/api/order_status',
  updatePassword: '/api/update_password',
  dashboard: '/api/dashboard',
  staffUsersCreate: '/api/staff_users_create/',
  staffUsersDashboard: '/api/staff-dashboard/',
  leads: '/api/contact_us/',
  subscriptions: '/api/subscription-plans/',
  userSubscription: '/api/user-subscription-plans/',
  getQrCodes: '/api/get-qr-codes/',
  saveQrCodes: '/api/save-qr-codes/',
  updateQrCode: '/api/update-qr-codes/',
  deleteQrCodes: '/api/delete-qr-codes/',
  getClients: '/api/get-clients/',
  saveClients: '/api/save-clients/',
  updateClient: '/api/update-clients/',
  deleteClients: '/api/delete-clients/',
  getAllVouchers: '/api/get-voucher/',
  getPurchasedVoucher: '/api/get-purchased-voucher/',
  savePurchasedVoucher: '/api/save-purchased-voucher/',
  saveVoucher: '/api/save-voucher/',
  uploadVouchers: '/api/upload_vouchers',
  deleteVoucher: '/api/delete-vouchers/',
};
