import loadable from '@loadable/component';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
const StaffUserDashboard = loadable(() => import('views/StaffUserDashboard'));
const PendingOrders = loadable(() =>
  import('views/StaffUserOrders/PendingOrders')
);
const CompletedOrders = loadable(() =>
  import('views/StaffUserOrders/CompletedOrders')
);
const UpadatePassword = loadable(() =>
  import('views/UpdatePassword/UpadatePassword')
);
const OrderPage = loadable(() => import('views/Orders/OrderPage'));
const OutForDelivery = loadable(() =>
  import('views/StaffUserOrders/OutForDelivery')
);

function Routes() {
  return (
    <Switch>
      <Route path='/update_password'>
        <UpadatePassword />
      </Route>
      <Route path='/completed_orders'>
        <CompletedOrders />
      </Route>
      <Route path='/assigned_orders'>
        <PendingOrders />
      </Route>
      <Route path='/out_for_delivery_orders'>
        <OutForDelivery />
      </Route>
      <Route path='/order/:id'>
        <OrderPage />
      </Route>
      <Route path='/dashboard'>
        <StaffUserDashboard />
      </Route>
      <Route
        exact
        path='/'
        render={() => (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        )}></Route>
    </Switch>
  );
}

export default Routes;
