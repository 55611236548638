import React from 'react';
import loadable from '@loadable/component';
import { Switch, Route, Redirect } from 'react-router-dom';
import QrCodes from 'views/QrCodes';
import ClientCompanies from 'views/ClientCompanies';
import Vouchers from 'views/Vouchers';
import CreateClientComapny from 'views/CreateClientComapny';
import Referrals from 'views/Referrals';
import Coupons from 'views/Coupons/Coupons';

const Dashboard = loadable(() => import('views/Dashboard'));
const PendingOrders = loadable(() => import('views/Orders/PendingOrders'));
const CompletedOrders = loadable(() => import('views/Orders/CompletedOrders'));
const AssignedOrders = loadable(() => import('views/Orders/AssignedOrders'));
const TotalOrders = loadable(() => import('views/Orders/TotalOrders'));
const Users = loadable(() => import('views/Users'));
const StaffUser = loadable(() => import('views/StaffUser'));
const OrderPage = loadable(() => import('views/Orders/OrderPage'));
const UpadatePassword = loadable(() =>
  import('views/UpdatePassword/UpadatePassword')
);
const UserProfile = loadable(() => import('views/UserProfile'));
const Leads = loadable(() => import('views/Leads'));
const Subscriptions = loadable(() =>
  import('views/Subscriptions/Subscriptions')
);
const UserSubscription = loadable(() =>
  import('views/UserSubscription/UserSubscription')
);
const SubscriptionPendingOrders = loadable(() =>
  import('views/SubscriptionOrders/SubscriptionPendingOrders')
);
const SubscriptionAssignedOrders = loadable(() =>
  import('views/SubscriptionOrders/SubscriptionAssignedOrders')
);
const SubscriptionCompletedOrders = loadable(() =>
  import('views/SubscriptionOrders/SubscriptionCompletedOrders')
);
const SubscriptionTotalOrders = loadable(() =>
  import('views/SubscriptionOrders/SubscriptionTotalOrders')
);

function Routes() {
  return (
    <Switch>
      <Route path='/leads'>
        <Leads />
      </Route>
      <Route path='/user/:id'>
        <UserProfile />
      </Route>
      <Route path='/update_password'>
        <UpadatePassword />
      </Route>
      <Route path='/staff_users'>
        <StaffUser />
      </Route>
      <Route path='/total_orders'>
        <TotalOrders />
      </Route>
      <Route path='/completed_orders'>
        <CompletedOrders />
      </Route>
      <Route path='/pending_orders'>
        <PendingOrders />
      </Route>
      <Route path='/assigned_orders'>
        <AssignedOrders />
      </Route>
      <Route path='/order/:id'>
        <OrderPage />
      </Route>

      <Route exact path='/qr_codes'>
        <QrCodes />
      </Route>
      <Route exact path='/client_companies'>
        <ClientCompanies />
      </Route>
      <Route exact path='/vouchers'>
        <Vouchers />
      </Route>
      <Route path='/referrals'>
        <Referrals />
      </Route>
      <Route exact path='/clientVouchers'>
        <Vouchers />
      </Route>
      <Route exact path='/client_companies/:viewType'>
        <CreateClientComapny />
      </Route>

      <Route path='/users'>
        <Users />
      </Route>
      <Route path='/coupons'>
        <Coupons />
      </Route>
      <Route path='/subscription_pending_orders'>
        <SubscriptionPendingOrders />
      </Route>
      <Route path='/subscription_assigned_orders'>
        <SubscriptionAssignedOrders />
      </Route>
      <Route path='/subscription_completed_orders'>
        <SubscriptionCompletedOrders />
      </Route>
      <Route path='/subscription_total_orders'>
        <SubscriptionTotalOrders />
      </Route>
      <Route path='/dashboard'>
        <Dashboard />
      </Route>
      <Route
        exact
        path='/'
        render={() => (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        )}></Route>
      <Route path='/subscriptions'>
        <Subscriptions />
      </Route>
      <Route path='/user_subscription'>
        <UserSubscription />
      </Route>
    </Switch>
  );
}

export default Routes;
