import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Fab, Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import ReferralContainer from './ReferralContainer';
import useAsyncDataFrom from 'hooks/useAsyncDataFrom';
import UserService from 'services/repo/UserService';
import { getReferrals } from 'apis/referrals';
import { LastPage } from '@material-ui/icons';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '2vh'
}));

function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            setIntersecting(entry.isIntersecting)
        );

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}
export default function Referrals() {
    const [referralsData, setReferralsData] = useState([])

    const updateData = async (shouldFromStart) => {
        if (shouldFromStart) {
            window.location.reload()
            return
        }
        return { data: await getReferrals(shouldFromStart), shouldFromStart }
    }

    const ref = useRef();
    const isVisible = useIsVisible(ref);
    const [pages, setPages] = useState(0)
    const [lastPage, setLastPage] = useState(-1)
    const [searchOver, setSearchOver] = useState(null)
    useEffect(() => {
        console.log("visible", pages)
        // const inter = setTimeout(() => {
        if (lastPage !== pages) {
            setLastPage(pages)
            updateData().then((dat) => {
                const data = dat.data
                const shouldFromStart = dat.shouldFromStart
                if (data.length !== 0) {
                    if (shouldFromStart) {
                        setLastPage(0)
                        setPages(1)
                        setReferralsData([...data]);
                    } else {
                        setPages(pages + 1)
                        setReferralsData([...referralsData, ...data]);
                    }
                    console.log(data)
                    if (data.length < 50) {
                        setSearchOver(true)
                    }
                    return
                }
                setSearchOver(true)

            })
        }
        // }, 500)
    }, [isVisible])
    return (
        <Box>
            <Grid container spacing={2}>
                {referralsData.map((referral, id) => {
                    return <Grid key={id} item xs={6}>
                        <Item sx={{ borderRadius: '2vh' }}>
                            <ReferralContainer updateData={updateData} data={referral} />
                        </Item>
                    </Grid>
                })}
            </Grid>
            <Box id="referral-loading-container" sx={{
                height: '10vh', width: '100%', display: searchOver == null ? 'flex' : "none", justifyContent: 'center', alignItems: 'center',
                bgcolor: '#388e3c', fontSize: '2.2vh', color: 'white', mt: '4vh',
            }} ref={searchOver == null ? ref : null}>{isVisible ? "...Loading..." : "...Loading..."}</Box>
        </Box>
    )
}
